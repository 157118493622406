<template>
    <nuxt-link :to="slug" class="flex flex-col mb-10 mt-12 lg:my-16 xl:my-24 overflow-hidden rounded-lg group">
        <div class="lg:block hidden group-hover:scale-[1.03] transition transition--slow">
            <img :src="desktopImage" :alt="title" data-not-lazy />
        </div>
        <div class="lg:hidden block">
            <img :src="mobileImage" :alt="title" data-not-lazy />
        </div>
    </nuxt-link>
</template>
<script>
export default {
    name: 'BannerZone',
    props: {
        title: {
            type: String,
            required: true,
        },
        slug: {
            type: String,
            required: true,
        },
        desktopImage: {
            type: String,
            required: true,
        },
        mobileImage: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.transition--slow {
    transition-duration: 0.5s;
}
</style>
