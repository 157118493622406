var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.highlighted_categories)?_c('div',{staticClass:"relative"},[_c('div',{staticClass:"flex w-[calc(100%+2.5rem)] -ml-5 lg:ml-0 lg:w-full flex-nowrap gap-x-4 lg:gap-x-6 items-center overflow-x-scroll nicescroll-light lg:overflow-visible px-4 lg:px-0",class:_vm.isOverlay
                ? 'text-white justify-start mb-6 pb-4 border-b border-white border-opacity-20'
                : 'justify-center mb-6'},[(_vm.isOverlay)?_c('h3',{staticClass:"font-Inter-Bold lg:mr-4 uppercase text-lg lg:text-xl hidden md:flex"},[_vm._v("\n            Kiemelt kategóriák\n        ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.cats.slice(0, 7)),function(category){return _c('button',{key:`category-${category.translations[0].id}`,staticClass:"whitespace-nowrap font-Inter-Bold bg-opacity-0 hover:bg-opacity-5 py-1 px-5 rounded-full transition border hover:border-opacity-100 border-transparent",class:{
                'text-white hover:text-white border-opacity-100 bg-opacity-100 hover:bg-opacity-100':
                    _vm.isActiveTab(category.translations[0].id) ||
                    _vm.selectedHighlightedCategory === parseInt(category.translations[0].id),
                'bg-blue-100 hover:text-blue-100 hover:border-blue-100': !_vm.isOverlay,
                'bg-white': _vm.isOverlay,
                'text-black hover:text-black':
                    (_vm.isOverlay && _vm.isActiveTab(category.translations[0].id)) ||
                    _vm.selectedHighlightedCategory === parseInt(category.translations[0].id),
            },on:{"click":function($event){return _vm.setHighlightedCategories(category.translations[0].id)}}},[_vm._v("\n            "+_vm._s(category.translations[0].name)+"\n        ")])})],2),_vm._v(" "),(_vm.products && _vm.products.length)?_c('div',[_c('div',{staticClass:"grid grid-cols-10 gap-3 lg:gap-4 mb-16 lg:mb-24"},_vm._l((_vm.products.slice(0, _vm.$device.isDesktop ? 5 : 6)),function(product){return _c('div',{key:`product-${product.id}`,staticClass:"col-span-5 lg:col-span-2 h-full",class:product?.has_banner ? 'lg:col-span-12' : ' col-span-5 lg:col-span-3'},[_c('div',{staticClass:"h-full",class:product?.has_banner && 'lg:grid lg:grid-cols-12 lg:gap-x-8'},[_c('div',{staticClass:"h-full",class:product?.has_banner && 'lg:col-span-3'},[_c('ProductCard',{staticClass:"h-full",attrs:{"title":product.name,"rating":5,"has-btn-text":false,"product":product,"slug":_vm.localePath({
                                    name: 'pictures-category-product',
                                    params: {
                                        category: product?.category_slug,
                                        product: product?.slug,
                                    },
                                }),"brutto-price":product.price,"is-favorite":product.isFavorite,"price":product.price,"discounted-price":product.discounted_price,"md":_vm.$device.isMobile,"image":product.preview}})],1)])])}),0)]):_c('div',{staticClass:"text-center pt-32 pb-32 my-8 rounded-2xl border border-black bg-white border-opacity-10 flex justify-center flex-col items-center"},[_c('img',{staticClass:"w-12 h-auto mb-4",attrs:{"src":require('~/assets/images/icons//sad-icon.svg'),"alt":"No result"}}),_vm._v(" "),_vm._m(0)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"font-Inter-Bold text-xl leading-8"},[_vm._v("Sajnos nincs megjeleníthető termék "),_c('br'),_vm._v("\n            a kiválasztott kategóriában")])
}]

export { render, staticRenderFns }